// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '_bootstrap.scss';
@import "bootstrap-select/sass/bootstrap-select.scss";

@import "_datetimepicker.scss";
@import "_summernote.scss";
@import "bootstrap-select/bootstrap-select.scss";
// @import "_kalkulator.scss";

html {
    min-height: 100%;
}

body {
    padding-top: 70px;
    min-height: 100%;
    background-color: #f0f0f0;
    background-attachment: fixed;
    @include gradient-vertical($start-color: #f0f0f0, $end-color: darken(#f0f0f0, 5%));
}

.pagination {
    margin: 0;
}

.form-signin {
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;

    .form-signin-heading,
    .checkbox {
        margin-bottom: 10px;
    }

    .checkbox {
        font-weight: normal;
    }

    .form-control {
        position: relative;
        height: auto;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 10px;
        font-size: 16px;

        &:focus {
            z-index: 2;
        }
    }

    input#email {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    input#password {
        margin-bottom: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

.well.szuro {
    margin-bottom: 0px;
}

.rendezheto {

    .thumbnail,
    tr,
    li {
        cursor: move;
    }
}

.icon-refresh-animate {
    animation: rotateThis 1s linear infinite;
    -webkit-animation: rotateThis 1s linear infinite;
}

@-webkit-keyframes rotateThis {
    from {
        transform: scale(1) rotate(0deg);
    }

    to {
        transform: scale(1) rotate(360deg);
    }
}

.ajax_loader {
    width: 80px;
    height: 50px;
    position: fixed;
    top: 200px;
    right: 50%;
    z-index: 100001;
    background-color: #ccc;
    text-align: center;
    border-radius: 5px;
    display: none;
    color: #fff;
    font-size: 22px;
    padding-top: 11px;
}

ul.nav.nav-tabs {
    margin-bottom: 15px;
}

.doboz {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}

.form-inline .bootstrap-select {
    width: auto !important;
}

fieldset+fieldset {
    margin-top: 10px;
}

.halvany {
    opacity: 0.4;
}

.margin-bottom-3 {
    margin-bottom: 3px;
}

.margin-top-3 {
    margin-top: 3px;
}

.margin-bottom-minus-2 {
    margin-bottom: -2px;
}

textarea.code {
    font-family: $font-family-monospace;
}

.panel-heading {
    h3.panel-title {
        .alcim {
            display: block;
            font-size: 70%;
            margin-top: 2px;
        }
    }
}

/* Form */
#adatok .input-append{}

#adatok .input-append label{
    display: block;
    margin-bottom: 0;
    font-weight: normal;
    color: #333;
    font-size: 14px;
}

#adatok .input-append input{
    border: 1px solid #bbb;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 8px 4px;
    outline: 0;
}

#adatok .input-append .add-on{
    background-image: linear-gradient(#eee, #ccc);
    padding: 10px 4px;
    border: 1px solid #aaa;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-left: -5px;
}

.megjegyzes{
    margin-top: 0;
    font-size: 11px;
    color: #aaa;
}

.fright {
    float: right !important;
}

#adatok .btn{
    display: block;
    height: 26px;
    padding: 5px;
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 26px;
    color: #ffffff;
    text-align: center;
    background: #222222;
    border: 0 none;
    box-sizing: content-box;
}

/* Table */
table#eredmeny{
    width: 100%;
}

table#eredmeny thead tr th{
    border: 0;
    text-align: right;
}

table#eredmeny tr:nth-of-type(odd){
        background-color: #f9f9f9;
}

table#eredmeny tr th{
    padding: 8px;
    line-height: 20px;
    vertical-align: top;
    border-top: 1px solid #ddd;
    text-align: right;
}

table#eredmeny tr td{
    padding: 8px;
    line-height: 20px;
    vertical-align: top;
    border-top: 1px solid #ddd;
    text-align: right;
}

.eredmeny_piros{
    color: #ff0000; font-weight: bold;
}

@media only screen and (max-width: 991px){
    #adatok table, #adatok table tbody tr{
            display: block;
            width: 100%;
    }

    #adatok table, #adatok table tbody tr td{
        display: block;
    }

    #adatok table tbody{
            display: block;
            width: 100%;
            overflow: hidden;
    }

    #szamol{
        margin: 1rem 0 !important;
    }

    #eredmeny{
        display: block;
        overflow: scroll;
    }

    #eredmeny tr th, #eredmeny tr td{
        padding: 4px !important;
        line-height: 1 !important;
        font-size: 12px;
    }
}
